.container.contact__container {
    width: 58%;
    display: grid;
    grid-template-columns: 30% 58%;
    gap: 12%;
}

.contact__options {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

.contact__option {
    background: var(--color-bg-variant);
    padding: 1.2rem;
    border-radius: 1.2rem;
    text-align: center;
    border: 1px solid transparent;
    transition: var(--transition);
}

.contact__option:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
}

.contact__option-icon {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}

.contact__option {
    display: inline-block;
    font-size: 0.8rem;
    text-decoration: none;
    color: var(--color-white);
    height: 30%;
}

form {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    margin-top: 1.2rem;
}

input, textarea {
    width: 100%;
    padding: 1.5rem;
    border-radius: 0.5rem;
    background: transparent;
    border: 2px solid var(--color-primary-variant);
    resize: none;
    color: var(--color-white);
}

#success {
    /* display: none; */
    border: 2px solid var(--color-white);
    color: var(--color-white);
    width: 15%;
    margin-top: 2rem;
    margin-left: 60%;
    transform: translateX(-40%);
    background-color: #53A653;
    padding: 1rem;
    border-radius: 1.2rem;
    transition: var(--transition);

}

#success #success-icon {
    margin-top: 0.1rem;
    margin-right: 1rem;
}


/* ------------------------------ MEDIA --------------------------- */

/* === Tablet media query === */


@media screen and (max-width: 1024px) {
    .container.contact__container {
        grid-template-columns: 1fr;
        gap: 2rem;
    }

    #success {
        width: 50%;
        margin-left: 50%;
        transform: translateX(-50%);
    }
}

/* === Small devices media query === */

@media screen and (max-width: 768px) {

    .container.contact__container {
        width: var(--container-width-sm);
    }

    #success {
        width: 90%;
    }
    
}